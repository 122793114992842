import { Route } from '@angular/router';

export const MENU_ROUTING = {
  DESKTOP: 'Desktop',
  MOBILE: 'Mobile',
};

export const MENU_ROUTES: Array<Route> = [
  {
    path: '',
    redirectTo: MENU_ROUTING.DESKTOP,
    pathMatch: 'full',
  },
  {
    path: MENU_ROUTING.DESKTOP,
    title: MENU_ROUTING.DESKTOP,
    loadComponent: async () =>
      (await import('@features/partners/builder/settings/menu/desktop/desktop.component')).DesktopComponent,
  },
  {
    path: MENU_ROUTING.MOBILE,
    title: MENU_ROUTING.MOBILE,
    loadComponent: async () =>
      (await import('@features/partners/builder/settings/menu/mobile/mobile.component')).MobileComponent,
  },
];
